import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

const ContributiPage = props => {
  const { path } = props

  return (
    <Layout path={path}>
      <SEO title="Contributi 2020" />
      <div style={{ maxWidth: 960, margin: "96px auto", marginTop: 192 }}>
        <h1>Elenco dei contributi ricevuti anni 2020/2021</h1>
        <table style={{ marginTop: 24, maxWidth: "100%" }}>
          <thead>
            <tr>
              <th>Ragione sociale</th>
              <th>QUATTRO RISTORAZIONE SRL</th>
            </tr>
            <tr>
              <th>Codice fiscale</th>
              <th>01146550287</th>
            </tr>
            <tr>
              <th>Indirizzo</th>
              <th>Via Umberto primo 31 - Padova</th>
            </tr>
            <tr>
              <th>CAP</th>
              <th>35122</th>
            </tr>
            <tr>
              <th>P62QHVQ</th>
              <th></th>
            </tr>
          </thead>
        </table>
        <table style={{ marginTop: 24 }}>
          <thead>
            <tr>
              <th>Soggetto erogante</th>
              <th>Somma incassata</th>
              <th>Data incasso</th>
              <th>Motivazione (se possibile con riferimento normativo)</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Agenzia delle entrate</td>
              <td>€ 8,204.00</td>
              <td>14/07/2020</td>
              <td>
                Contributo a fondo perduto decreto "RILANCIO" (D.L. 34/2020)
              </td>
            </tr>
            <tr>
              <td>Agenzia delle entrate</td>
              <td>€ 16,408.00</td>
              <td>10/11/2020</td>
              <td>
                Contributo a fondo perduto decreto "RISTORI" (D.L. 137/2020)
              </td>
            </tr>
            <tr>
              <td>Fin. Medio Credito</td>
              <td>€ 30,000.00</td>
              <td>09/12/2020</td>
              <td></td>
            </tr>
            <tr>
              <td>Agenzia delle entrate</td>
              <td>€ 8,204.00</td>
              <td>31/12/2020</td>
              <td>
                Contributo a fondo perduto decreto "NATALE" (D.L. 172 N.1)
              </td>
            </tr>
            <tr>
              <td>MIN. ECONOMIA E FINANZE</td>
              <td>€ 2,126.00</td>
              <td></td>
              <td>IRAP SALDO 2019</td>
            </tr>
            <tr>
              <td>MIN. ECONOMIA E FINANZE</td>
              <td>€ 461.00</td>
              <td></td>
              <td>IRAP ACCONTO 2020</td>
            </tr>
            <tr>
              <td>CRED. IMP 2020 LOCAZIONI</td>
              <td>€ 6,569.40</td>
              <td></td>
              <td>D.L. 34/2020 ART. 28</td>
            </tr>
            <tr>
              <td>CRED. IMP 2020 LOCAZIONI</td>
              <td>€ 5,254.80</td>
              <td></td>
              <td>D.L. 137/2020 ART. 8</td>
            </tr>
            <tr>
              <td>Agenzia delle entrate</td>
              <td>€ 224.00</td>
              <td></td>
              <td>Credito d’imposta sanificazione (D.L. 34/2020)</td>
            </tr>
            <tr>
              <td>Regione Veneto</td>
              <td>€ 9,328.60</td>
              <td></td>
              <td>Fondo filiera ristorazione</td>
            </tr>
            <tr>
              <td>Rai</td>
              <td>€ 29.94</td>
              <td></td>
              <td>Esenzione canone Rai 2021</td>
            </tr>
            <tr>
              <td>Agenzia delle Entrate</td>
              <td>€ 9,560.00</td>
              <td>27/04/2021</td>
              <td>
                Contributo a fondo perduto decreto "Sostegni" (D.L. 41/2021)
              </td>
            </tr>
            <tr>
              <td>Agenzia delle Entrate</td>
              <td>€ 9,560.00</td>
              <td>24/06/2021</td>
              <td>
                Contributo a fondo perduto decreto "Sostegni bis autom." (D.L.
                73/2021)
              </td>
            </tr>
            <tr>
              <td>Agenzia delle Entrate</td>
              <td>€ 9,875.00</td>
              <td>31/12/2021</td>
              <td>
                Contributo a fondo perduto decreto "Sostegni bis pereq." (D.L.
                73/2021)
              </td>
            </tr>
            <tr>
              <td>Inps</td>
              <td>€ 3,699.20</td>
              <td>2021</td>
              <td>L. 178/2020 decontribuzione</td>
            </tr>
            <tr>
              <td>Inps</td>
              <td>€ 10,083.60</td>
              <td>01/2022 - 05/2022</td>
              <td>D.L. 73/2021</td>
            </tr>
          </tbody>
        </table>
      </div>
    </Layout>
  )
}

export default ContributiPage
